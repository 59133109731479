import { BehaviorSubject } from "rxjs";

export namespace SharedEvents {

    export class ScreenIsMobileEvent {
        private static event = new BehaviorSubject<boolean>(false);
        public static listener = this.event.asObservable();
        public static pushIsMobile = () => this.event.next(true);
        public static pushIsNotMobile = () => this.event.next(false);
    }

    export class SiteLanguageEvent {
        private static event = new BehaviorSubject<'ar' | 'en'>('ar');
        public static listener = this.event.asObservable();
        public static pushAr = () => this.event.next('ar');
        public static pushEn = () => {
            this.event.next('en');
            console.log("switch to en")
        }
    }

}