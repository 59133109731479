import { Injectable, inject } from '@angular/core';
import { GroupedLookupResult, LookupResult, LookupsService, RegionLookupResult } from '../../../openapi';
import { Observable, map, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CachedLookupServiceService {

  private readonly _loadedLookups = new Map<string, any>();
  private readonly _lookupService = inject(LookupsService);

  constructor() { }

  public setCachedLookups(lookupsKey: 'contractTypes' | 'advertiseTypes' | 'propertyTypes' | 'cities', value: any) {
    this._loadedLookups.set(lookupsKey, value);
  }

  public contractTypes(): Observable<LookupResult[] | null | undefined> {
    var cachedValue = this._loadedLookups.get('contractTypes');
    if (cachedValue) {
      return of(cachedValue)
    }

    return this._lookupService.contractType().pipe(map(p => p.data!));
  }

  public advertiseTypes(): Observable<LookupResult[] | null | undefined> {
    var cachedValue = this._loadedLookups.get('advertiseTypes');
    if (cachedValue) {
      return of(cachedValue)
    }

    return this._lookupService.advertiseTypes().pipe(map(p => p.data!));
  }

  public propertyTypes(): Observable<GroupedLookupResult[] | null | undefined> {
    var cachedValue = this._loadedLookups.get('propertyTypes');
    if (cachedValue) {
      return of(cachedValue)
    }

    return this._lookupService.propertyTypes().pipe(map(p => p.data!));;
  }

  public cities(): Observable<RegionLookupResult[] | null | undefined> {
    var cachedValue = this._loadedLookups.get('cities');
    if (cachedValue) {
      return of(cachedValue);
    }

    return this._lookupService.cities().pipe(map(p => p.data!));
  }


  public districts(id: number): Observable<LookupResult[] | null | undefined> {
    var cachedValue = this._loadedLookups.get('districts');
    if (cachedValue) {
      return of(cachedValue);
    }

    return this._lookupService.districts(id).pipe(map(p => p.data!));
  }

}
