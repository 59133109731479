import { AccountService } from './../../../openapi/api/account.service';
import { BASE_PATH } from './../../../openapi/variables';
import { HttpClient } from '@angular/common/http'
import { Inject, Injectable, InjectionToken, inject } from '@angular/core'
import { Router } from '@angular/router'
import { BehaviorSubject, Subject, Subscription, interval } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AccountInformationQueryResult } from '../../../openapi';
import { UserRoles } from '../../../../../shared/src/lib/public.api';
import { convertToBoolean } from '../../../../../shared/src/public-api';
import { BrokerSharedEvents } from '../../shared/events';


@Injectable({
  providedIn: 'root',
})
export class AuthService {


  private $isLoggedInSubject = new Subject<boolean>();
  public isLoggedIn = this.$isLoggedInSubject.asObservable();
  public isLoggedInValue: boolean = false;

  private readonly UDATA_KEY = "udata"
  private readonly UACC_KEY = "uacc"
  public static readonly accessTokenKey = "access_token";

  public get userData(): UserTokenData | undefined {
    const userData = localStorage.getItem(this.UDATA_KEY);
    if (userData) {
      return new UserTokenData(JSON.parse(userData));
    }
    return undefined;
  }


  public get userAccount(): AccountInformationQueryResult | undefined {
    const userAccount = localStorage.getItem(this.UACC_KEY);
    if (userAccount)
      return JSON.parse(userAccount) as AccountInformationQueryResult;
    return undefined;
  }



  constructor(private _accountService: AccountService, private _jwtHelperService: JwtHelperService,
    private router: Router) {
    this.startWatchingToken();
  }

  public refreshData() {
    this.$isLoggedInSubject.next(true);
  }

  public postSigning(accessToken: string, withRedirect: boolean = true) {
    this.accessToken = accessToken;
    const tokenPayload = this._jwtHelperService.decodeToken(accessToken);
    this.setUserData(tokenPayload);
    this.startWatchingToken();
    this._accountService.getInformation().subscribe(response => {
      localStorage.setItem(this.UACC_KEY, JSON.stringify(response.data));
      this.$isLoggedInSubject.next(true);
      BrokerSharedEvents.SubscriptionStatusUpdated.push(response.data.canUseSystem);
      if (convertToBoolean(tokenPayload.requireProfileSelection) == true) {
        this.router.navigate(['/auth/profile-selector']);
      } else if (withRedirect) {
        this.router.navigate(['/dashboard']);
      }
    });

  }

  reloadUserInformation() {
    const accessToken = localStorage.getItem(AuthService.accessTokenKey);
    this.postSigning(accessToken, false);
  }

  public signOut() {
    const currentLang = localStorage.getItem('lang') ?? 'ar';
    localStorage.clear();
    localStorage.setItem('lang', currentLang);
    this.router.navigate(['/auth/login']);
  }


  private _accessToken: string | undefined | null;
  public get accessToken(): string | undefined | null {
    if (localStorage.getItem(AuthService.accessTokenKey)) {
      this._accessToken = localStorage.getItem(AuthService.accessTokenKey);
    } else {
      this._accessToken = undefined;
    }
    return this._accessToken;
  }
  public set accessToken(v: string | undefined | null) {
    this._accessToken = v;
    if (v)
      localStorage.setItem(AuthService.accessTokenKey, v);
    else
      localStorage.removeItem(AuthService.accessTokenKey)
  }


  private setUserData(udata: any) {
    BrokerSharedEvents.UserDataUpdated.push(true);
    localStorage.setItem(this.UDATA_KEY, JSON.stringify(udata));
  }

  private readonly maxLogCount = 20;
  private logCounts = 0;
  private logDebug(message: string) {
    if (this.logCounts >= this.maxLogCount) {
      return;
    }

    this.logCounts++;
  }

  private $TokenWatcher = new Subscription();
  private startWatchingToken() {

    this.$TokenWatcher.unsubscribe();
    this.$TokenWatcher = interval(500).subscribe({
      next: () => {

        if (!this.accessToken) {
          this.logDebug("Token Not Founded");

          if (this.isLoggedInValue != false) {
            this.isLoggedInValue = false;
            this.$isLoggedInSubject.next(this.isLoggedInValue);
          }
          return;
        }

        if (this._jwtHelperService.isTokenExpired(this.accessToken, 5) == false) {
          this.logDebug("Token Not Expired, will at: " + this._jwtHelperService.getTokenExpirationDate(this.accessToken));
          if (this.isLoggedInValue != true) {
            this.isLoggedInValue = true;
            this.$isLoggedInSubject.next(this.isLoggedInValue);
          }
          return;
        }

        localStorage.clear();

      },
      error: () => {
        this.$TokenWatcher.unsubscribe();
      }
    })
  }

  public isRequireProfileSelection() {
    const token = this._jwtHelperService.decodeToken(this.accessToken);
    if (!token) {
      return false;
    }
    const isBroker = token.isBroker == "true";
    return !isBroker && token.requireProfileSelection == "true";
  }

  public isAuthenticated() {
    if (!this.accessToken) {
      return false;
    }

    return this._jwtHelperService.isTokenExpired(this.accessToken, 5) == false;

  }

  public hasPermission(permission: number[]): boolean {
    if (!this.userAccount
      || !this.userAccount.permissions
      || this.userAccount.permissions.length == 0) {
      return false;
    }

    var subscriptionHasPermission = this.userAccount.subscriptionPermissions?.filter(x => permission.indexOf(x) > -1).length > 0;
    if (subscriptionHasPermission == false) {
      return false;
    }

    var userHasPermission = this.userAccount.permissions.filter(x => permission.indexOf(x) > -1).length > 0;
    return userHasPermission;
  }

  public isBroker(): boolean {
    if (!this.userAccount
      || !this.userAccount.permissions
      || this.userAccount.permissions.length == 0) {
      return false;
    }

    return this.userAccount.role.id == UserRoles.Broker;
  }

  public isTeamMember(): boolean {
    if (!this.userAccount
      || !this.userAccount.permissions
      || this.userAccount.permissions.length == 0) {
      return false;
    }

    return this.userAccount.role.id == UserRoles.TeamMember;
  }

  public getLimitationValue(featureId: number): number | null {
    const feature = this.userAccount.subscriptionLimitations.filter(x => x.featureId == featureId);

    if (feature.length > 0) {
      const value = feature[0].value;
      return value > 0 ? value : null;
    }

    return null;
  }
}

export const AuthGuard = (permissions: number[]) => {
  const hasPermission = inject(AuthService).hasPermission(permissions);
  const router = inject(Router);
  if (hasPermission) {
    return true;
  } else {
    router.navigate(['/unauthorized']);
    return false;
  }
}

export const IsBrokerGuard = () => {
  const authService = inject(AuthService);
  return authService.isBroker();
}

export const IsTeamMember = () => {
  const authService = inject(AuthService);
  return authService.isTeamMember();
}

export const IsAuthenticatedGuard = () => {
  const authService = inject(AuthService);
  const iaAuthenticated = authService.isAuthenticated();
  const router = inject(Router);
  if (iaAuthenticated) {
    return true;
  } else {
    authService.signOut();
    return false;
  }
}

export const IsNotAuthenticatedGuard = () => {
  const authService = inject(AuthService);
  const iaAuthenticated = authService.isAuthenticated();
  if (iaAuthenticated) {
    console.log('user is authenticated, redirected to dashboard.');
    const router = inject(Router);
    router.navigateByUrl('/dashboard');
    return false;
  } else {
    return true;
  }
}


export default class UserTokenData {
  email: string;
  given_name: string;
  isBroker: boolean;
  nameid: string;
  requireProfileSelection: boolean;
  subscriptionExpiration: Date;
  subscriptionType: string;

  constructor(data: {
    email: string;
    given_name: string;
    isBroker: string; // Keeping this as string for conversion
    nameid: string;
    requireProfileSelection: string; // Keeping this as string for conversion
    subscriptionExpiration: string; // Keeping this as string for conversion
    subscriptionType: string;
  }) {
    this.email = data.email;
    this.given_name = data.given_name;
    this.isBroker = data.isBroker.toLowerCase() === 'true'; // Convert to boolean
    this.nameid = data.nameid;
    this.requireProfileSelection = data.requireProfileSelection.toLowerCase() === 'true'; // Convert to boolean
    this.subscriptionExpiration = new Date(data.subscriptionExpiration); // Convert to Date
    this.subscriptionType = data.subscriptionType;
  }


}