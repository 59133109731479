export { convertToDate } from "./date.helper"

export function getDirection() {
  const dir = localStorage.getItem('lang') === 'en' ? 'ltr' : 'rtl'
  return getDirectionByDir(dir);
}

export function getDirectionByDir(dir: 'rtl' | 'ltr') {
  if (dir === 'rtl') {
    return {
      Next: 'left',
      Pervious: 'right',
      First: 'right',
      Last: 'left',
    }
  } else {
    return {
      Next: 'right',
      Pervious: 'left',
      First: 'left',
      Last: 'right',
    }
  }
}

export function getDefultDirection() {
  return {
    Next: 'left',
    Pervious: 'right',
    First: 'right',
    Last: 'left',
  }
}


export function parsePhoneNumber(phoneNumber: string | null | undefined): string | null | undefined {
  const number = phoneNumber;
  if (!number) {
    return null;
  }

  if (number.startsWith('+966')) {
    return number;
  }

  if (number.startsWith('0')) {
    return `+966${number.substring(1)}`;
  }

  return `+966${number.substring(number.length - 9)}`;
}

export function sendWhatsapp(whatsAppNumber: string, message: string) {
  if (whatsAppNumber) {
    const link = `https://wa.me/${whatsAppNumber}?text=${message}`;
    window.open(link, '_blank')?.focus();
  }
}


export function getFutureTimeInSeconds(secondsToAdd: number): Date {
  const now = new Date();
  const futureTime = new Date(now.getTime() + secondsToAdd * 1000);
  return futureTime;
}

export function scrollPageTo(elementId: string) {
  const element = document.getElementById(elementId);
  const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;

  window.scrollTo({
    top: elementPosition,
    behavior: 'smooth'
  });

}

export function convertToBoolean(input: string): boolean | undefined {
  try {
    return JSON.parse(input.toLowerCase());
  }
  catch (e) {
    return undefined;
  }
}