import { TranslateService } from '@ngx-translate/core';
import { Pipe, PipeTransform, inject } from '@angular/core';

@Pipe({
  name: 'lookupParser',
  standalone: true
})
export class LookupParserPipe implements PipeTransform {

  private readonly translateService = inject(TranslateService);

  constructor() {


  }

  transform(value: { id: number | undefined | null, name: string | null | undefined, nameAr: string | null | undefined }): string {

    if (!value) {
      return '-';
    }
    return (value as any)[this.translateService.instant('lookup')];
  }

}


