import { BehaviorSubject } from "rxjs";

export namespace BrokerSharedEvents {

    export class UserDataUpdated {
        private static event = new BehaviorSubject<boolean>(false);
        public static listener = this.event.asObservable();
        public static push = (status: boolean) => this.event.next(status);
    }

    export class SubscriptionStatusUpdated {
        private static event = new BehaviorSubject<boolean>(true);
        public static listener = this.event.asObservable();
        public static push = (status: boolean) => this.event.next(status);
    }

}