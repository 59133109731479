export function getYearsBackFromDate(date: Date, years: number) {
  const newDate = new Date(date);
  newDate.setFullYear(newDate.getFullYear() - years);
  return newDate;
}

export function getYearsBackFromString(date: string, years: number) {
  const newDate = new Date(date);
  newDate.setFullYear(newDate.getFullYear() - years);
  return newDate;
}


export function convertToDate(dateString: string) {
  const date = new Date(dateString);
  date.setDate(date.getDate() + 1); // Adding one day
  return date;
}
