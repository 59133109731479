export class UserRoles {
  static readonly SuperAdmin = 1;
  static readonly Broker = 2;
  static readonly TeamMember = 3;

}

export namespace UserPermissions {

  export class Broker {
    static readonly PropertyAds_ViewAll = 1000;
    static readonly PropertyAds_ViewBrokerAds = 1001;
    static readonly PropertyAds_Create = 1002;
    static readonly PropertyAds_Publish = 1003;
    static readonly PropertyAds_Edit = 1004;
    static readonly PropertyAds_Delete = 1005;
    static readonly PropertyAds_Archive = 1006;
    static readonly PropertyAds_ViewActionLogs = 1007;

    static readonly ClientsManagement_ViewAll = 1100;
    static readonly ClientsManagement_ViewBrokerRequests = 1101;
    static readonly ClientsManagement_Create = 1102;
    static readonly ClientsManagement_Edit = 1103;
    static readonly ClientsManagement_Delete = 1104;
    static readonly ClientsManagement_ViewActionLogs = 1105;
    static readonly ClientsManagement_ViewAll_ClientProfile = 1106;
    static readonly ClientsManagement_Create_ClientProfile = 1107;
    static readonly ClientsManagement_Edit_ClientProfile = 1108;
    static readonly ClientsManagement_Delete_ClientProfile = 1109;
    static readonly ClientsManagement_ViewActionLogs_ClientProfile = 1110;

    static readonly InterestRequests_ViewAll = 1200;
    static readonly InterestRequests_ViewBrokerInterests = 1201;
    static readonly InterestRequests_ConvertToClientRequest = 1202;
    static readonly InterestRequests_Ignore = 1203;
    static readonly InterestRequests_ViewActionLogs = 1204;

    static readonly TeamManagement_ManageTeam = 1300;
    static readonly TeamManagement_ManageMembership = 1301;
    static readonly TeamManagement_ViewTeamMemberActionLogs = 1302;
    static readonly TeamManagement_ViewMembershipActionLogs = 1303;

    static readonly Subscriptions_ManageSubscriptions = 1500;

    static readonly SiteAdRequests_ViewAll = 1400;
    static readonly SiteAdRequests_ViewSiteAdRequests = 1401;
    static readonly SiteAdRequests_ConvertToClientRequest = 1402;
    static readonly SiteAdRequests_Ignore = 1403;
  }

  export class TeamMember {
    static readonly PropertyAds_ViewAll = 2000;
    static readonly PropertyAds_ViewBrokerAds = 2001;
    static readonly PropertyAds_Create = 2002;
    static readonly PropertyAds_Publish = 2003;
    static readonly PropertyAds_Edit = 2004;
    static readonly PropertyAds_Delete = 2005;
    static readonly PropertyAds_Archive = 2006;

    static readonly ClientsManagement_ViewAll = 2100;
    static readonly ClientsManagement_ViewBrokerRequests = 2101;
    static readonly ClientsManagement_Create = 2102;
    static readonly ClientsManagement_Edit = 2103;
    static readonly ClientsManagement_Delete = 2104;
    
    static readonly ClientsManagement_ViewAll_ClientProfile = 2105;
    static readonly ClientsManagement_Create_ClientProfile = 2106;
    static readonly ClientsManagement_Edit_ClientProfile = 2107;
    static readonly ClientsManagement_Delete_ClientProfile = 2108;
    static readonly ClientsManagement_ViewActionLogs_ClientProfile = 2109;

    static readonly InterestRequests_ViewAll = 2200;
    static readonly InterestRequests_ViewBrokerInterests = 2201;
    static readonly InterestRequests_ConvertToClientRequest = 2202;
    static readonly InterestRequests_Ignore = 2203;

    static readonly TeamManagement_ViewMembers = 2300;
    static readonly TeamManagement_CreateMembers = 2301;
    static readonly TeamManagement_EditMembers = 2302;
    static readonly TeamManagement_DeleteMembers = 2303;

    static readonly SiteAdRequests_ViewAll = 2400;
    static readonly SiteAdRequests_ViewSiteAdRequests = 2401;
    static readonly SiteAdRequests_ConvertToClientRequest = 2402;
    static readonly SiteAdRequests_Ignore = 2403;
  }

}
