export namespace Constants {

  export class AdvertiseType {
    public static readonly Sell = 1;
    public static readonly Rent = 2;
    public static readonly Investment = 3;
  }

  export class AdvertiseStatus {
    public static readonly Draft = 1;
    public static readonly Published = 2;
    public static readonly Archived = 3;
    public static readonly Completed_Published = 4;
    public static readonly Completed = 5;
    public static readonly Canceled = 6;
  }

  export class AdvertisePublishType {
    public static readonly Public = 1;
    public static readonly Private = 2;
    public static readonly RestrictedAccess = 3;
  }

  export class PropertyTypeCategory {
    public static readonly Land = 1;
    public static readonly TowersAndBuildings = 2;
    public static readonly CommercialProperties = 3;
    public static readonly ResidentialProperties = 4;
    public static readonly HospitalityAndRecreation = 5;
  }

  export class TeamMemberReliability {
    public static readonly High = 1;
    public static readonly Medium = 2;
    public static readonly Low = 3;
  }

  export class ClientRequestPriority {
    public static readonly High = 1;
    public static readonly Medium = 2;
    public static readonly Low = 3;
  }

  export class TeamMemberRequestStatuses {
    public static readonly Waiting = 'Waiting';
    public static readonly Approved = 'Approved';
    public static readonly Rejected = 'Rejected';
  }

  export class InterestRequestStatuses {
    public static readonly New = 1;
    public static readonly Ignored = 2;
    public static readonly Accepted = 3;
  }

  export class SiteAdRequestStatuses {
    public static readonly New = 1;
    public static readonly Ignored = 2;
    public static readonly Accepted = 3;
  }

  export class TeamMembershipType {
    /**
     * موظف
     */
    public static readonly Employee = 1;
    /**
     * متاون 
     */
    public static readonly Agent = 2;
  }

  export class Language {
    public static readonly Arabic = 'ar';
    public static readonly English = 'en';
  }
}
